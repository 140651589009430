<template>
  <div id="app">
    <router-link class="title" to="/">涂鸦-Doodles</router-link>
    <div class="tab">
      <ul>
        <router-link v-for="(item,xh) in category" :class="{on:cur==item.id}" :to="'/category/'+item.id" :key="xh"
                     tag="li">
          {{item.text}}
        </router-link>
        <!--        <li v-for="(item,xh) in category" :class="{on:cur==item.id}" @click="cur=item.id" :key="xh">{{item.text}}</li>-->
      </ul>
    </div>
    <div class="con">
      <ul>
        <li v-for="(item,xh) in listFilter" :key="xh">
          <router-link :to="'/doodles/'+item.url">
            <div class="img">
              <img :src="item.tpic" v-if="item.tpic.indexOf('http')>-1">
              <img v-else :src="'pic/'+item.tpic">
            </div>
            <div class="text">
              <p class="time">{{item.mtime|toEnglish}}</p>
              <p class="ti">{{item.title}}</p>
            </div>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
    name: 'index',
    data() {
        return {
            list: [],
            category: [
                {
                    id: 0,
                    text: '全部'
                },
                {
                    id: 1,
                    text: '动画图片'
                },
                {
                    id: 2,
                    text: '互动游戏'
                },
                {
                    id: 3,
                    text: '幻灯片'
                },
                {
                    id: 4,
                    text: '静态图片'
                },
                {
                    id: 5,
                    text: '视频'
                }
            ]
        }
    },
     props: {
        cur: {
            type: Number,
            default: 0 //默认0
        },
    },
    computed: {
        listFilter() {
            if (this.cur == 0 || null) {
                return this.list

            } else {
                return this.list.filter((el) => {
                    return el.category == this.cur
                })
            }
        }
    },
    filters: {
        toEnglish: (value) => {
            let year, month, day, cMonth
            year = value.split('-')[0]
            month = value.split('-')[1]
            day = value.split('-')[2]
            switch (month) {
                case '01':
                    cMonth = 'January';
                    break;
                case '02':
                    cMonth = 'February';
                    break;
                case '03':
                    cMonth = 'March';
                    break;
                case '04':
                    cMonth = 'April';
                    break;
                case '05':
                    cMonth = 'May';
                    break;
                case '06':
                    cMonth = 'June';
                    break;
                case '07':
                    cMonth = 'July';
                    break;
                case '08':
                    cMonth = 'August';
                    break;
                case '09':
                    cMonth = 'September';
                    break;
                case '10':
                    cMonth = 'October';
                    break;
                case '11':
                    cMonth = 'November';
                    break;
                case '12':
                    cMonth = 'December';
                    break;
            }
            return cMonth + ' ' + day + ',' + year
        },

    },
    methods: {},
    created() {
        let sendData = {
            app_key: '1D5AF16FD7D90EE3C5CF70D088249505',
            model_name: 'game',
            perpage: 1000,
            order: ["mtime DESC", "id DESC"]
        }
        if (this.$store.state.doodles.doodles.length === 0) {
            this.$store.dispatch('doodles/getList', sendData).then((rs) => {
                this.list = rs
            })
        } else {
            this.list = this.$store.state.doodles.doodles
        }

    },
    watch: {
        // $route(to) {
        //     if (to.path.length>1) {
        //         this.cur = to.path.split('/')[2]
        //     } else {
        //         this.cur = 0
        //     }
        //     console.log(to)
        // }
    },
}
</script>

